import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from  '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angular2-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxPrintModule } from 'ngx-print';
import { AppComponent } from './app.component';
import { environment as env } from 'src/environments/environment';

import { orderSlicePipe } from './common/pipe/order-slice.pipe';
import { UserFriendlyTimePipe } from './common/pipe/user-friendly-time.pipe';
import { HotelAdminPanelAuthGuardService } from './hotel-admin-panel/hotel-admin-panel-auth-guard.service';
import { HotelLoginGuardService } from './hotel-admin-panel/hotel-admin-login/hotel-login-guard.service';
import { HotelAdminPanelAuthService } from './hotel-admin-panel/hotel-admin-panel-auth.service';
import { HotelAdminPanelService } from './hotel-admin-panel/hotel-admin-panel.service';
import { StaffFrontDeskPanelAuthGuardService } from './staff-front-desk-panel/staff-front-desk-panel-auth-guard.service';
import { StaffLoginGuardService } from './staff-front-desk-panel/staff-login/staff-login-guard.service';
import { StaffFrontDeskPanelAuthService } from './staff-front-desk-panel/staff-front-desk-panel-auth.service';
import { StaffFrontDeskPanelService } from './staff-front-desk-panel/staff-front-desk-panel.service';
import { GuestPanelAuthGuardService } from './guest-panel/guest-panel-auth-guard.service';
import { GuestQrCodeLoginGuardService } from './guest-panel/guest-qr-code-login/guest-qr-code-login-guard.service';
import { GuestPanelAuthService } from './guest-panel/guest-panel-auth.service';
import { GuestPanelService } from './guest-panel/guest-panel.service';

import { AmenitiesManagementComponent } from './hotel-admin-panel/amenities-management/amenities-management.component';
import { FoodManagementComponent } from './hotel-admin-panel/food-management/food-management.component';
import { HotelAdminChangeLogoComponent } from './hotel-admin-panel/hotel-admin-change-logo/hotel-admin-change-logo.component';
import { HotelAdminChangePasswordComponent } from './hotel-admin-panel/hotel-admin-change-password/hotel-admin-change-password.component';
import { HotelAdminDetailComponent } from './hotel-admin-panel/hotel-admin-detail/hotel-admin-detail.component';
import { HotelAdminLoginComponent } from './hotel-admin-panel/hotel-admin-login/hotel-admin-login.component';
import { HotelAdminBottomNavigationComponent } from './hotel-admin-panel/hotel-admin-master-panel/hotel-admin-bottom-navigation/hotel-admin-bottom-navigation.component';
import { HotelAdminLeftNavigationComponent } from './hotel-admin-panel/hotel-admin-master-panel/hotel-admin-left-navigation/hotel-admin-left-navigation.component';
import { HotelAdminTopNavigationComponent } from './hotel-admin-panel/hotel-admin-master-panel/hotel-admin-top-navigation/hotel-admin-top-navigation.component';
import { HotelSettingLeftNavigationComponent } from './hotel-admin-panel/hotel-admin-master-panel/hotel-setting-left-navigation/hotel-setting-left-navigation.component';
import { HotelAdminResetPasswordComponent } from './hotel-admin-panel/hotel-admin-reset-password/hotel-admin-reset-password.component';
import { RoomManagementComponent } from './hotel-admin-panel/room-management/room-management.component';
import { RoomServiceManagementComponent } from './hotel-admin-panel/room-service-management/room-service-management.component';
import { StaffManagementComponent } from './hotel-admin-panel/staff-management/staff-management.component';

import { GuestManagementComponent } from './staff-front-desk-panel/guest-management/guest-management.component';
import { GuestRoomServiceRequestComponent } from './staff-front-desk-panel/guest-room-service-request/guest-room-service-request.component';
import { GuestMealOrderRequestComponent } from './staff-front-desk-panel/guest-meal-order-request/guest-meal-order-request.component';
import { GuestRoomStayDetailsComponent } from './staff-front-desk-panel/guest-room-stay-details/guest-room-stay-details.component';
import { StaffDetailComponent } from './staff-front-desk-panel/staff-detail/staff-detail.component';
import { StaffChangePasswordComponent } from './staff-front-desk-panel/staff-change-password/staff-change-password.component';
import { StaffForgotPasswordComponent } from './staff-front-desk-panel/staff-forgot-password/staff-forgot-password.component';
import { StaffPanelBottomNavigationComponent } from './staff-front-desk-panel/staff-front-desk-panel-master/staff-panel-bottom-navigation/staff-panel-bottom-navigation.component';
import { StaffPanelLeftNavigationComponent } from './staff-front-desk-panel/staff-front-desk-panel-master/staff-panel-left-navigation/staff-panel-left-navigation.component';
import { StaffPanelSettingLeftNavigationComponent } from './staff-front-desk-panel/staff-front-desk-panel-master/staff-panel-setting-left-navigation/staff-panel-setting-left-navigation.component';
import { StaffPanelTopNavigationComponent } from './staff-front-desk-panel/staff-front-desk-panel-master/staff-panel-top-navigation/staff-panel-top-navigation.component';
import { StaffLoginComponent } from './staff-front-desk-panel/staff-login/staff-login.component';

import { GuestPanelBottomNavigationComponent } from './guest-panel/guest-panel-master/guest-panel-bottom-navigation/guest-panel-bottom-navigation.component';
import { GuestPanelTopNavigationComponent } from './guest-panel/guest-panel-master/guest-panel-top-navigation/guest-panel-top-navigation.component';
import { GuestQrCodeLoginComponent } from './guest-panel/guest-qr-code-login/guest-qr-code-login.component';
import { RoomServiceRequestComponent } from './guest-panel/room-service-request/room-service-request.component';
import { RoomDiningRequestComponent } from './guest-panel/room-dining-request/room-dining-request.component';
import { AppliancesComponent } from './guest-panel/appliances/appliances.component';
import { MiscellaneousSettingComponent } from './hotel-admin-panel/miscellaneous-setting/miscellaneous-setting.component';
import { HotelAvailabilityComponent } from './hotel-admin-panel/hotel-availability/hotel-availability.component';


@NgModule({
  declarations: [
    AppComponent,
    orderSlicePipe,
    UserFriendlyTimePipe,
    AmenitiesManagementComponent,
    FoodManagementComponent,
    HotelAdminChangeLogoComponent,
    HotelAdminChangePasswordComponent,
    HotelAdminDetailComponent,
    HotelAdminLoginComponent,
    HotelAdminBottomNavigationComponent,
    HotelAdminLeftNavigationComponent,
    HotelAdminTopNavigationComponent,
    HotelSettingLeftNavigationComponent,
    HotelAdminResetPasswordComponent,
    RoomManagementComponent,
    RoomServiceManagementComponent,
    StaffManagementComponent,
    GuestManagementComponent,
    GuestMealOrderRequestComponent,
    RoomServiceRequestComponent,
    GuestRoomStayDetailsComponent,
    StaffDetailComponent,
    StaffChangePasswordComponent,
    StaffForgotPasswordComponent,
    StaffPanelBottomNavigationComponent,
    StaffPanelLeftNavigationComponent,
    StaffPanelSettingLeftNavigationComponent,
    StaffPanelTopNavigationComponent,
    StaffLoginComponent,
    GuestPanelBottomNavigationComponent,
    GuestPanelTopNavigationComponent,
    GuestQrCodeLoginComponent,
    RoomDiningRequestComponent,
    GuestRoomServiceRequestComponent,
    AppliancesComponent,
    MiscellaneousSettingComponent,
    HotelAvailabilityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ZXingScannerModule,
    NgbModule,
    NgxPrintModule,
	  ToastrModule.forRoot(
      {
        timeOut: 3000,
        preventDuplicates: true,
      }
    ),
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: StaffLoginComponent,
        canActivate: [StaffLoginGuardService],
      },
      {
        path: 'front-desk/detail',
        component: StaffDetailComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      {
        path: 'front-desk/change-password',
        component: StaffChangePasswordComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      {
        path: 'front-desk/forgot-password',
        component: StaffForgotPasswordComponent,
      },
      {
        path: 'front-desk/guest-panel',
        component: GuestManagementComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      {
        path: 'front-desk/guest-room-stay-detail',
        component: GuestRoomStayDetailsComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      {
        path: 'front-desk/guest-meal-order-request',
        component: GuestMealOrderRequestComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      {
        path: 'front-desk/guest-room-service-request',
        component: GuestRoomServiceRequestComponent,
        canActivate: [StaffFrontDeskPanelAuthGuardService],
      },
      // {
      //   path: 'hotel-admin',
      //   redirectTo: 'hotel-admin/login',
      //   pathMatch: 'full',
      // },
      // {
      //   path: 'hotel-admin/login',
      //   component: HotelAdminLoginComponent,
      //   canActivate: [HotelLoginGuardService],
      // },
      // {
      //   path: 'hotel-admin/detail',
      //   component: HotelAdminDetailComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/change-hotel-availability',
      //   component: HotelAvailabilityComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/change-hotel-logo',
      //   component: HotelAdminChangeLogoComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/change-password',
      //   component: HotelAdminChangePasswordComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/forgot-password',
      //   component: HotelAdminResetPasswordComponent,
      // },
      // {
      //   path: 'hotel-admin/room-management',
      //   component: RoomManagementComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/room-service-management',
      //   component: RoomServiceManagementComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/food-management',
      //   component: FoodManagementComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/staff-management',
      //   component: StaffManagementComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/miscellaneous-setting',
      //   component: MiscellaneousSettingComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'hotel-admin/amenities-management',
      //   component: AmenitiesManagementComponent,
      //   canActivate: [HotelAdminPanelAuthGuardService],
      // },
      // {
      //   path: 'guest',
      //   redirectTo: 'guest/scan-qr',
      //   pathMatch: 'full',
      // },
      // {
      //   path: 'guest/scan-qr',
      //   component: GuestQrCodeLoginComponent,
      //   canActivate: [GuestQrCodeLoginGuardService],
      // },
      // {
      //   path: 'guest/room-daining',
      //   component: RoomDiningRequestComponent,
      //   canActivate: [GuestPanelAuthGuardService],
      // },
      // {
      //   path: 'guest/room-service',
      //   component: RoomServiceRequestComponent,
      //   canActivate: [GuestPanelAuthGuardService],
      // },
      // {
      //   path: 'guest/appliances',
      //   component: AppliancesComponent,
      //   canActivate: [GuestPanelAuthGuardService],
      // },
    ]),
    HttpClientModule,
    AngularFireModule.initializeApp(env.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    FileUploadModule,
  ],
  providers: [
    HotelAdminPanelAuthService,  
    HotelAdminPanelService,
    HotelLoginGuardService,
    HotelAdminPanelAuthGuardService,
    StaffFrontDeskPanelAuthService,
    StaffFrontDeskPanelService,
    StaffLoginGuardService,
    StaffFrontDeskPanelAuthGuardService,
    GuestPanelAuthService,
    GuestPanelService,
    GuestQrCodeLoginGuardService,
    GuestPanelAuthGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
