import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StaffFrontDeskPanelService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private httpClient: HttpClient,
    private angularFireMessaging: AngularFireMessaging
  ) { 
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
      )
      }requestPermission() {
      this.angularFireMessaging.requestToken.subscribe(
      (token) => {
      console.log(token);
      },
      (err) => {
      console.error('Unable to get permission to notify.', err);
      }
      );
      }receiveMessage() {
      this.angularFireMessaging.messages.subscribe(
      (payload) => {
      console.log("new message received. ", payload);
      this.currentMessage.next(payload);
      })
  }

   // store hotel session data
   storeHotelStaffUserSessionData(data, isSignIn){
    data['scope'] = 'hotelStaffUser'
    localStorage.setItem('hotelStaffUserData', JSON.stringify(data))
    if(isSignIn){
      localStorage.setItem('isSignIn', JSON.stringify(isSignIn))
    }
    return({
      'status': 1,
      'msg': 'hotel staff user session details successfully set.'
    });
  };

  // get hotel staff user session data
  getHotelStaffUserSessionData(){
    let hotelStaffUserData = JSON.parse(localStorage.getItem('hotelStaffUserData'));
    let isSignIn = JSON.parse(localStorage.getItem('isSignIn'));
    return({
      'status': 1,
      'hotelStaffUserData': hotelStaffUserData,
      'isSignIn': isSignIn,
      'msg': 'hotel staff user session details successfully get.'
    });
  };

  // clear hotel staff user session data
  clearHotelStaffUserSessionData(){
    localStorage.removeItem('isSignIn')
    localStorage.removeItem('hotelStaffUserData')
    return({
      'status': 1,
      'msg': 'hotel staff user session details successfully clear.'
    });
  };

  // get hotel staff user detail
  getHotelStaffUserDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/get-staff-details/',
        data
    );
  };

  changePassword(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/change-password/',
        data
    );
  };

  forgotPasswordGenerateOTP(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/forgot-password-generate-otp/',
        data
    );
  };

  forgotPasswordVerifyOTP(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/forgot-password-verify-otp/',
        data
    );
  };

  getErrorResponse(message){
    if(message){
      const result = message.split(':');
      if(result && result.length == 2){
        let objKey = result[0];
        let objValue = result[1];
        let errorObj = {}
        errorObj[objKey] = objValue
        return errorObj
      }
      return {}
    }
    return {}
   
  };

  validateCurrentHotelStaffDetail(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/staff/validate-staff-user-details/',
      data
    );
  };

  getGuestStayListByHotel(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/get-guest-list-by-hotel/',
        data
    );
  };

  getRoomListByHotel(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/get-room-list-by-hotel/',
        data
    );
  };

  searchGuestStayList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/get-guest-list-by-search-parameter/',
        data
    );
  };

  createGuestStay(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/create-guest-stay/',
        data
    );
  };

  getGuestOrderAndServiceDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/get-guest-food-order-room-service-details/',
        data
    );
  };

  updateGuestStayDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/update-guest-stay-detail/',
        data
    );
  };

  sendLoginCodeToGuest(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/send-login-guest-details/',
        data
    );
  };

  isFoodBeverageServiceEnable(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/staff/is-food-beverage-service-enable/',
        data
    );
  };

  

}
