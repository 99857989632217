import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Router} from "@angular/router"
import { Subscription } from 'rxjs';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-staff-login',
  templateUrl: './staff-login.component.html',
  styleUrls: ['./staff-login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaffLoginComponent implements OnInit {
  currentMessage = new BehaviorSubject(null);
  subscription: Subscription;
  emailOrMobileNumber;
  fcmToken: string = '';
  emailOrPasswordErrorMessage: string = ''
  isSignIn: boolean = true;
  password: string;
  loginErrorMessage: string;
  isUserLogin: boolean = false;
  message;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
    private angularFireMessaging: AngularFireMessaging
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })
    this.requestPermission()
    this.receiveMessage()
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  validateEmailOrMobileNumber(){
    this.emailOrPasswordErrorMessage = '';
    if(isNaN(this.emailOrMobileNumber) == true) {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if(reg.test(this.emailOrMobileNumber) == false) {
          this.emailOrPasswordErrorMessage = 'Please enter a valid email'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
    else if (isNaN(this.emailOrMobileNumber) == false) {
      let reg_mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      if (reg_mobile.test(this.emailOrMobileNumber) == false) {
        this.emailOrPasswordErrorMessage = 'Please enter a valid mobile no.'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
  }

  login(){
    this.loginErrorMessage = null;
    this.isUserLogin = true;
    let data = {
      'email_or_mobile': this.emailOrMobileNumber, 
      'password': this.password
    }
    if(this.fcmToken && (this.fcmToken != '' || this.fcmToken != null)){
      data['fcm_token'] = this.fcmToken
    }
    this.httpClient.post(
      environment.apiBaseURL + '/api/staff/login/',
      data
    ).subscribe(response => {
        if(response['status'] === 1 && response['data'] && response['data']['is_front_desk_user'] == true){
          let data = response['data']
          let result = this.staffFrontDeskPanelService.storeHotelStaffUserSessionData(data, this.isSignIn)
          if(result['status'] == 1){
            if(data && 'hotel_department_name' in data && data['hotel_department_name'] == 'Food & Beverages'){
              this.router.navigate(['/front-desk/guest-meal-order-request'])
            }
            else{
              this.router.navigate(['/front-desk/guest-panel'])
            }
          }
          else{
            this.isUserLogin = false;
          }
        }
        else{
          this.isUserLogin = false;
          this.loginErrorMessage = 'Please enter a correct mobile no. and password.';
        }
    })
  }

  isSignInToggle(event) {
    if (event.target.checked) {
      this.isSignIn = true;
    }
    else{
      this.isSignIn = false;
    }
  }

  requestPermission(){
    this.angularFireMessaging.requestToken.subscribe((token) => {
        this.fcmToken = token;
        console.log(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
    });
  };

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log("new message received. ", payload);
      console.log(payload);
      this.currentMessage.next(payload);
    })
  };

}
